const FooterData = [
  {
    title: 'company',
    submenu: [
      {
        title: 'About company',
      },
      {
        title: 'Company services',
      },
      {
        title: 'Job opportunities',
      },
      {
        title: 'Contact us',
      },
    ],
  },
  {
    title: 'customer',
    submenu: [
      {
        title: 'Client support',
      },
      {
        title: 'Pricing packages',
      },
      {
        title: 'Company history',
      },
      {
        title: 'Our process',
      },
    ],
  },
  {
    title: 'Additional',
    submenu: [
      {
        title: 'Our story',
      },
      {
        title: 'Who we are',
      },
      {
        title: 'Our process',
      },
      {
        title: 'Latest news',
      },
    ],
  },
  {
    title: 'Services',
    submenu: [
      {
        title: 'Brand experience',
      },
      {
        title: 'e-Commerce website',
      },
      {
        title: 'Content writing',
      },
      {
        title: 'Marketing strategy',
      },
    ],
  },
  {
    title: 'Resources',
    submenu: [
      {
        title: 'Theme guide',
      },
      {
        title: 'Support desk',
      },
      {
        title: 'What we offer',
      },
      {
        title: 'Company history',
      },
    ],
  },
  {
    title: 'Categories',
    submenu: [
      {
        title: 'For men',
      },
      {
        title: 'For woman',
      },
      {
        title: 'Accessories',
      },
      {
        title: 'Collections',
      },
    ],
  },
  {
    title: 'Adavantages',
    submenu: [
      {
        title: 'Free delivery',
      },
      {
        title: '100 days refund',
      },
      {
        title: 'Multiple payments',
      },
      {
        title: 'Sustainable',
      },
    ],
  },
]

export default FooterData
