const IconWithTextData_01 = [
  {
    icon: 'line-icon-Navigation-LeftWindow text-basecolor text-[40px] text-fastblue',
    title: 'Amazing layouts',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.',
  },
  {
    icon: 'line-icon-Cursor-Click2 text-basecolor text-[40px] text-fastblue',
    title: 'No coding required',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.',
  },
  {
    icon: 'line-icon-Like-2 text- text-[40px] text-fastblue',
    title: 'Easy to customize',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.',
  },
  {
    icon: 'line-icon-Talk-Man text-basecolor text-[40px] text-fastblue',
    title: 'Customer satisfaction',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.',
  },
  {
    icon: 'line-icon-Heart text-basecolor text-[40px] text-fastblue',
    title: 'Huge icon collection',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.',
  },
  {
    icon: 'line-icon-Gear-2 text-basecolor text-[40px] text-fastblue',
    title: 'Powerfull customize',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.',
  },
]

const IconWithTextData_02 = [
  {
    icon: 'line-icon-Navigation-LeftWindow text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]',
    title: 'Powerfull theme options',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore.',
  },
  {
    icon: 'line-icon-Cursor-Click2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]',
    title: 'Unlimited layouts and styles',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore.',
  },
  {
    icon: 'line-icon-Archery-2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]',
    title: 'Developing an effective strategy',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore.',
  },
  {
    icon: 'line-icon-Female text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]',
    title: 'Automated testing and support',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore.',
  },
]

const IconWithTextData_03 = [
  {
    icon: 'line-icon-Navigation-LeftWindow text-gradient bg-gradient-to-r from-[#0039e3] via-[#5e28dd] via-[#741bd9] to-[#8600d4] text-[40px]',
    title: 'Amazing layouts',
    content: 'Lorem ipsum is simply dummy text the printing typesetting',
  },
  {
    icon: 'line-icon-Cursor-Click2 text-gradient bg-gradient-to-r from-[#0039e3] via-[#5e28dd] via-[#741bd9] to-[#8600d4] text-[40px]',
    title: 'No coding required',
    content: 'Lorem ipsum is simply dummy text the printing typesetting',
  },
  {
    icon: 'line-icon-Gear-2 text-gradient bg-gradient-to-r from-[#0039e3] via-[#5e28dd] via-[#741bd9] to-[#8600d4] text-[40px]',
    title: 'Easy to customize',
    content: 'Lorem ipsum is simply dummy text the printing typesetting',
  },
  {
    icon: 'line-icon-Talk-Man text-gradient bg-gradient-to-r from-[#0039e3] via-[#5e28dd] via-[#741bd9] to-[#8600d4] text-[40px]',
    title: 'Customer satisfaction',
    content: 'Lorem ipsum is simply dummy text the printing typesetting',
  },
]

const IconWithTextData_04 = [
  {
    icon: 'line-icon-Cursor-Click2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]',
    title: 'Innovative business',
    content: 'Easy to customize',
  },
  {
    icon: 'line-icon-Bakelite text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]',
    title: 'Expertly marketing',
    content: 'High quality services',
  },
  {
    icon: 'line-icon-Boy text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]',
    title: 'Engaging audiences',
    content: 'Build perfect websites',
  },
]

const IconWithTextData_05 = [
  {
    icon: 'line-icon-Vector-5',
    title: 'Good design',
  },
  {
    icon: 'line-icon-Police',
    title: 'Inspiring ideas',
  },
  {
    icon: 'line-icon-Bee',
    title: 'Innovative code',
  },
  {
    icon: 'line-icon-Medal-2',
    title: 'Best quality',
  },
  {
    icon: 'line-icon-Idea-5',
    title: 'Brand analysis',
  },
]

const IconWithTextData_06 = [
  {
    icon: 'line-icon-Android-Store text-fastblue',
    title: 'eCommerce development',
    content:
      'Lorem ipsum dolor sit consectetur adipiscing elit eiusmod incididunt.',
    linkTitle: 'READ MORE',
    link: '/page/what-we-offer',
  },
  {
    icon: 'line-icon-Bakelite text-fastblue',
    title: 'Design and development',
    content:
      'Lorem ipsum dolor sit consectetur adipiscing elit eiusmod incididunt.',
    linkTitle: 'READ MORE',
    link: '/page/what-we-offer',
  },
  {
    icon: 'line-icon-Archery-2 text-fastblue',
    title: 'Social media marketing',
    content:
      'Lorem ipsum dolor sit consectetur adipiscing elit eiusmod incididunt. ',
    linkTitle: 'READ MORE',
    link: '/page/what-we-offer',
  },
]

const IconWithTextData_07 = [
  {
    icon: 'line-icon-Money-Bag text-[#2ed47c]',
    title: 'Effective strategy',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing eiusmod tempor incididunt labore et dolore magna tempor.',
    linkTitle: 'LEARN MORE',
    link: '/page/our-services',
  },
  {
    icon: 'line-icon-Talk-Man text-[#2ed47c]',
    title: 'Customer satisfaction',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing eiusmod tempor incididunt labore et dolore magna tempor.',
    linkTitle: 'LEARN MORE',
    link: '/page/our-services',
  },
  {
    icon: 'line-icon-Gear-2 text-[#2ed47c]',
    title: 'Powerfull customize',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing eiusmod tempor incididunt labore et dolore magna tempor. ',
    linkTitle: 'LEARN MORE',
    link: '/page/our-services',
  },
]

const IconWithTextData_08 = [
  {
    icon: 'line-icon-Cursor-Click2',
    title: 'Powerfull options',
    content: 'Lorem ipsum dolor consectet eiusmod tempor incididunt',
  },
  {
    icon: 'line-icon-Sand-watch2',
    title: 'Unlimited variations',
    content: 'Lorem ipsum dolor consectet eiusmod tempor incididunt',
  },
  {
    icon: 'line-icon-Idea-5',
    title: 'Effective strategy',
    content: 'Lorem ipsum dolor consectet eiusmod tempor incididunt ',
  },
]

const IconWithTextData_09 = [
  {
    icon: 'feather-monitor text-gradient bg-gradient-to-r from-[#ec606c] via-[#ee6362] via-[#ee6757] via-[#ed6c4d] to-[#eb7274]',
    title: 'Online marketing',
  },
  {
    icon: 'feather-clock text-gradient bg-gradient-to-r from-[#ec606c] via-[#ee6362] via-[#ee6757] via-[#ed6c4d] to-[#eb7274]',
    title: 'Real time analytics',
  },
  {
    icon: 'feather-thumbs-up text-gradient bg-gradient-to-r from-[#ec606c] via-[#ee6362] via-[#ee6757] via-[#ed6c4d] to-[#eb7274]',
    title: 'Boost performance',
  },
  {
    icon: 'feather-globe text-gradient bg-gradient-to-r from-[#ec606c] via-[#ee6362] via-[#ee6757] via-[#ed6c4d] to-[#eb7274]',
    title: 'Google advertising',
  },
]

const IconWithTextData_10 = [
  {
    icon: 'line-icon-Navigation-LeftWindow text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]',
    title: 'Amazing layouts',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text.',
  },
  {
    icon: 'line-icon-Cursor-Click2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]',
    title: 'No coding required',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text.',
  },
  {
    icon: 'line-icon-Like-2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]',
    title: 'Easy to customize',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text.',
  },
  {
    icon: 'line-icon-Talk-Man text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]',
    title: 'Customer satisfaction',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text.',
  },
  {
    icon: 'line-icon-Heart text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]',
    title: 'Huge icon collection',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text.',
  },
  {
    icon: 'line-icon-One-FingerTouch text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]',
    title: 'Responsive layout',
    content:
      'Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text.',
  },
]

const IconWithTextData_11 = [
  {
    icon: 'line-icon-Cursor-Click2  text-fastblue',
    title: 'Step 1',
    content:
      'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum text',
  },
  {
    icon: 'line-icon-Archery-2 text-fastblue',
    title: 'Step 2',
    content:
      'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum text',
  },
  {
    icon: 'line-icon-Medal-2 text-fastblue',
    title: 'Step 3',
    content:
      'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum text',
  },
]
export {
  IconWithTextData_01,
  IconWithTextData_02,
  IconWithTextData_03,
  IconWithTextData_04,
  IconWithTextData_05,
  IconWithTextData_06,
  IconWithTextData_07,
  IconWithTextData_08,
  IconWithTextData_09,
  IconWithTextData_10,
  IconWithTextData_11,
}
